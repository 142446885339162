import Logo from "../../../images/TwentyEightDays/Logo-Alphabody-White.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.scss";
import React from "react";

const ThankYou = () => {

  const [searchParams] = useSearchParams();
  const [alpha, setAlpha] = React.useState(null)
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log("alpha", searchParams.get("alpha"));
    if (searchParams.get("alpha") === null) {
      setAlpha('all');
    } else {
      setAlpha(`${searchParams.get("alpha")}`)
    }
  }, []);

  const [btnForm, setBtnForm] = React.useState(false)
  const goForm = () => {
    navigate(`/immersion/formulaire?email=${email}&form=true`);
  };
  React.useEffect(() => {
    console.log("BtnForm", searchParams.get("btn"));
    if (searchParams.get("btn") === null || searchParams.get("btn") === "false") {
      setBtnForm(false);
    } else {
      setBtnForm(true);
    }
  }, []);

  const [email, setEmail] = React.useState(false)
  React.useEffect(() => {
    console.log("email", searchParams.get("email"));
    if (searchParams.get("email") === null) {
      setEmail('');
    } else {
      setEmail(searchParams.get("email"));
    }
  }, []);

  return (
    <div className="tyCtn">
      <div className="logoCtn">
        <img className="logo" src={Logo} alt="logo" width={200} />
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      {(alpha === 'jean-philippe' || alpha === 'all') &&
        <div className="content">
          <div className="header">
            <h1 className="title">
              LE TEMOIGNAGE DE JEAN-PHILIPPE
            </h1>
            <br />
          </div>
          <div className="videoWrapper">
            <iframe
              src="https://player.vimeo.com/video/764446602?h=8257e93334&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className="video"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="TEMOIGNAGE IMMERSION JEAN-PHILIPPE"
            ></iframe>
          </div>
        </div>
      }
      {(alpha === 'franck' || alpha === 'all') &&
        <div className="content">
          <div className="header">
            <h1 className="title">
              LE TEMOIGNAGE DE FRANCK
            </h1>
            <br />
          </div>
          <div className="videoWrapper">
            <iframe
              src="https://player.vimeo.com/video/764446567?h=3f4778bd5e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className="video"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="TEMOIGNAGE IMMERSION FRANCK"
            ></iframe>
          </div>
        </div>
      }
      {(alpha === 'jonathan' || alpha === 'all') &&
        <div className="content">
          <div className="header">
            <h1 className="title">
              LE TEMOIGNAGE DE JONATHAN
            </h1>
            <br />
          </div>
          <div className="videoWrapper">
            <iframe
              src="https://player.vimeo.com/video/764446524?h=1e42144d3c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className="video"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="TEMOIGNAGE IMMERSION JONATHAN"
            ></iframe>
          </div>
        </div>
      }
      {(alpha === 'julien' || alpha === 'all') &&
        <div className="content">
          <div className="header">
            <h1 className="title">
              LE TEMOIGNAGE DE JULIEN
            </h1>
            <br />
          </div>
          <div className="videoWrapper">
            <iframe
              src="https://player.vimeo.com/video/769465075?h=fa15f5bd49&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className="video"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="TEMOIGNAGE IMMERSION JULIEN"
            ></iframe>
          </div>
        </div>
      }
      {(alpha === 'nicolas' || alpha === 'all') &&
        <div className="content">
          <div className="header">
            <h1 className="title">
              LE TEMOIGNAGE DE NICOLAS
            </h1>
            <br />
          </div>
          <div className="videoWrapper">
            <iframe
              src="https://player.vimeo.com/video/769465009?h=5248e3a84f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className="video"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="TEMOIGNAGE IMMERSION NICOLAS"
            ></iframe>
          </div>
        </div>
      }
      {(alpha === 'michel' || alpha === 'all') &&
        <div className="content">
          <div className="header">
            <h1 className="title">
              LE TEMOIGNAGE DE MICHEL
            </h1>
            <br />
          </div>
          <div className="videoWrapper">
            <iframe
              src="https://player.vimeo.com/video/764446629?h=a614e88c7f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className="video"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="TEMOIGNAGE IMMERSION MICHEL"
            ></iframe>
          </div>
        </div>
      }
      {
        btnForm &&
        <div >
          <button
            className="btn uppercase"
            type="submit" onClick={() => goForm()}>
            Je m'inscris à la liste d'attente
          </button>
        </div>
      }
    </div>
  );
};

export default ThankYou;
