import image from "../../../images/TwentyEightDays/nico-av.png";
import "./style.scss";

const Section8 = () => {
  return (
    <div className="container7dcSection6">
      <div className="content">
        <div className="container-title">
          <div className="center">
            <h2 className="bg-grey">Qui suis-je ?</h2>
            <br />
            <h1 className="grey">
              <strong>Moi c’est Nicolas (Nico pour les intimes)</strong>
            </h1>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="text bg-flou">
            <p className="grey">
              J’ai passé plus de 5 ans à m’entrainer en salle de sport, sans
              jamais avoir de vrais résultats.{" "}
            </p>
            <p className="grey">
              Un jour, j’ai découvert une méthode que personne n’utilise, et en
              3 mois, elle m’a permis de passer de ça (photo de gauche) à ça
              (photo de droite).
            </p>
            <div className="image">
              <img src={image} alt="nico" />
            </div>
            <p className="grey">
              J’étais tellement choqué par mes résultats, que j’ai décidé de
              rendre ma méthode accessible à tous, pour que tout le monde soit
              capable d’obtenir le corps dont il rêve, sans devoir se priver ou
              se tuer à la salle.
            </p>
            <p className="grey">
              Plus de 1 million de personnes ont décidé de rejoindre le 7 Days
              Challenge pendant le confinement, et ont réussi à perdre du poids.
            </p>
            <p className="grey">
              Avec mon équipe de coachs, on a déjà aidé et accompagné plus de 10
              000 hommes et femmes à transformer leur corps, à obtenir un
              physique sec et taillé, et à enfin se sentir bien dans ses
              baskets.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section8;
