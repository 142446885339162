import "./style.scss";

const Section5 = () => {
  return (
    <div className="containerSection5">
      <div className="content">

        <h1 className="white upperCase">
          Tu penses certainement<br />
          que pour avoir un <strong className="black">beau corps, musclé, dessiné et tonique</strong>,<br />
          tu dois :
        </h1>

        <div className="list">
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Passer tout ton temps à la salle
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Courir ou faire énormément de cardio
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Te priver de nourriture pour réduire tes calories au maximum afin de perdre du gras
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Manger des aliments que tu n’aimes pas ou manger uniquement de la salade quand on t’invite à un barbecue
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Avoir une meilleure génétique
            </p>
          </div>
        </div>

        <h2 className="bg-white">
          Ce n’est pas de ta faute.
        </h2>
        <h3 className="">
          <strong>Tu as été mal aiguillé, mal conseillé.</strong>
        </h3>
        <p className="white">
          Les mauvaises informations sont monnaie courante dans le domaine et malheureusement cela t’a fait commettre des erreurs qui t’ont amené à l’échec.
        </p>

      </div>
    </div>
  );
};

export default Section5;
