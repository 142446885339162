import "./style.scss";

const Section2 = () => {
  return (
    <div className="container7dcSection2">
      <div className="content">
        <h3 className="grey">
          <strong>
            Ce challenge, je l’ai créé spécialement pour les personnes :
          </strong>
        </h3>

        <div>
          <div className="line">
            <span className="check">✔</span>
            <p>
              Qui en ont assez de prendre des mois pour perdre 2 petits kilos
            </p>
          </div>
          <div className="line">
            <span className="check">✔</span>
            <p>
              Qui n’ont pas le temps (ou l’envie) d’aller tous les jours à la
              salle
            </p>
          </div>
          <div className="line">
            <span className="check">✔</span>
            <p>
              Qui ont déjà testé 5 régimes, 4 programmes de sport, 3 brûleurs de
              graisses, avec 0 résultat
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
