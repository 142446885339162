import Logo from "../../../images/TwentyEightDays/Logo-Alphabody-White.png";
import Temoignage from "../../../images/temoignage-franck.png";
import "./style.scss";

const ThankYou = () => {
  return (
    <div className="tyCtn">
      <div className="logoCtn">
        <img className="logo" src={Logo} alt="logo" width={200} />
      </div>

      <div className="content">
        <div className="header">
          <h1 className="title">
            Nous avons bien reçu tes informations.
            <br />
            Nous reviendrons vers toi pour te proposer un entretien téléphonique
            si tu es sélectioné.
          </h1>
          <br />
          <h2 className="subTitle">
            En attendant, découvre les témoignage poignants de Michel et Franck
          </h2>
        </div>
        <div className="videoWrapper">
          <iframe
            src="https://player.vimeo.com/video/764446629?h=a614e88c7f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            className="video"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="TEMOIGNAGE IMMERSION MICHEL"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <div className="imgCtn">
          <img className="img" src={Temoignage} alt="temoignage-franck" />
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
