import Button from "../Button";
import "./style.scss";

const Section9 = () => {
  return (
    <div className="container7dcSection9">
      <div className="content">
        <div className="container-text">
          <h2 className="grey titleSection4">
            <strong>FAQ</strong>
          </h2>

          <p>
            <strong>
              Je n’ai pas le temps d’aller à la salle, est ce ça marche pour moi
              ?
            </strong>
          </p>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Oui. Les séances du 7 Days Challenge sont à faire directement
              depuis chez toi, et te durent environ 45 minutes.
            </p>
          </div>
          <br />
          <p>
            <strong>J’aime faire du cardio</strong>
          </p>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Dans ce cas, continue d’en faire ! Je ne dénigre pas le cardio
              long. Je dis que ce n’est pas une bonne solution dans un objectif
              de perte de poids.
            </p>
          </div>
          <br />
          <p>
            <strong>Je suis un régime spécial (végan, végétarien, etc)</strong>
          </p>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Ça ne pose aucun problème. Il te suffit de réadapter tes assiettes
              pour qu’elles contiennent les bons macronutriments (protéines,
              lipides, glucides), et en bonne quantité, avec différents
              aliments.
            </p>
          </div>
          <br />
          <p>
            <strong>Comment vais-je accéder au challenge ?</strong>
          </p>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Tu auras accès à toutes les vidéos du challenge directement depuis
              une plateforme, accessible depuis un ordinateur, un portable ou
              une tablette. Tes identifiants te seront envoyés directement après
              ton paiement.
            </p>
          </div>
          <Button section="section-9" />
        </div>
      </div>
    </div>
  );
};

export default Section9;
