import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Logo from "../../../images/TwentyEightDays/Logo-Alphabody-White.png";
import Immersion from "../../../images/prochaine-immersion.png";
import "./style.scss";

const Optin = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [check, setCheck] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const optin = async () => {
    // verif si check
    console.log("check", check);
    console.log("email", email);
    if (!check) {
      setError(true);
      setMsg("Vous devez accepter les conditions");
    } else if (!!!email) {
      setError(true);
      setMsg("Merci de renseigner un email");
    } else {
      //verifier l'adresse mail avec active campaign
      let recupContact = false;
      const options = {
        method: "GET",
        url: "https://api-rest.alphabody.fr:2053/active-campaign/contact",
        params: { email: email }, // zytoon007@gmail.com
      };

      await axios.request(options).then(function (response) {
        if (response.data.contactId !== null) {
          recupContact = true;
        } else {
          recupContact = false;
        }
      });
      console.log("recupContact", recupContact);
      if (!recupContact) {
        setError(true);
        setMsg(
          "Merci de renseigner l'adresse mail que tu utilise pour Alphabody"
        );
      } else {
        setError(false);
        sendOptin();
      }
    }
  };

  const sendOptin = async () => {
    var options = {
      method: "POST",
      url: "https://hook.integromat.com/tyq605wgt1bqgwfqc6gbl6sruzg3to9n",
      headers: { "Content-Type": "application/json" },
      data: {
        email: email,
      },
    };

    await axios
      .request(options)
      .then(function (response) {
        console.log("sendOptin", response.data);
      })
      .catch(function (error) {
        console.error("sendOptinError", error);
      });

    goNextPage();
  };

  const goNextPage = () => {
    navigate(`/immersion/formulaire?email=${email}`);
  };

  return (
    <div className="optinCtn">
      <div className="logoCtn">
        <img className="logo" src={Logo} alt="logo" width={200} />
      </div>

      <div className="content">
        <div className="header">
          <h1 className="title uppercase">
            Pour les hommes qui en veulent plus
          </h1>
          <h2 className="subtitle">
            <span className="grey">Comment</span> Exploser{" "}
            <span className="grey">dans tous les Domaines de ta Vie</span> :
            Santé, Relations, Argent <span className="grey">et</span> Mental
          </h2>
        </div>

        <div className="formContainer">
          <div className="immersionImg">
            <img className="img" src={Immersion} alt="immersion" />
            <p className="imgTxt">
              aura lieu en mai 2023, dans le sud de la France
            </p>
          </div>

          <div className="formCtn">
            <div className="formTitle">
              <p className="textEmail">
                Rentre ton mail pour recevoir le documentaire complet
              </p>
              <p className="arrow-icon">⬇</p>
            </div>
            <div className="form">
              <input
                className="input-text"
                type="text"
                placeholder="entre ton email ici"
                onChange={(e) => setEmail(e.target.value)}
              />

              <div className="checkboxCtn">
                <label className="checkboxLabel" htmlFor="checkbox">
                  <input
                    type="checkbox"
                    id="checkbox"
                    name="checkbox"
                    onClick={() => setCheck(!check)}
                  />
                  <p className="text-check">
                    Ce documentaire est stritement confidentiel. Je m'engage à
                    ne la divulguer sous aucun prétexte.
                  </p>
                </label>
              </div>
              {error && <span className="errorForm">{msg}</span>}
              <button className="btn" onClick={() => optin()}>
                Oui, envoyez-moi le documentaire gratuit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Optin;
