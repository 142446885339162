import Food from "../../../images/SevenDays/food.jpg";
import Trealmill from "../../../images/SevenDays/treadmill.jpg";
import "./style.scss";

const Section3 = () => {
  return (
    <div className="container7dcSection3">
      <div className="content">
        <div className="card">
          <div className="line">
            <p>
              Combien de fois tu as déjà essayé de te priver de manger ou de
              suivre des régimes drastiques ?
            </p>
          </div>
          <div className="line">
            <p>
              Combien de fois tu t’es inscrit à la salle de sport, pour finir
              par payer ton abonnement pour rien ?
            </p>
          </div>
          <div className="line">
            <p>
              Combien de programmes gratuits tu as suivi sur Youtube ou
              Instagram, pour abandonner au bout de quelques jours ?
            </p>
          </div>
          <div className="line">
            <p>
              Et surtout, combien de fois toutes ces méthodes t’ont donné de
              vrais résultats sur le long terme ?
            </p>
          </div>
          <div className="line">
            <p>
              Parce que pour perdre tes kilos en trop, et ne pas les récupérer
              au bout de quelques mois…
            </p>
          </div>
          <br />

          <div className="withPhoto">
            <div className="imgCtn">
              <img className="img-food" src={Food} alt="food" />
            </div>
            <p className="textPicture">
              Tu n’as pas besoin d’arrêter de manger du pain, des pâtes, du
              chocolat, et tout ce qui te fait plaisir…
            </p>
          </div>

          <div className="withPhoto">
            <p className="textPicture">
              Ou de courir pendant des heures sur un tapis tous les jours.
            </p>
            <div className="imgCtn">
              <img className="img-treadmill" src={Trealmill} alt="trealmill" />
            </div>
          </div>

          <br />
          <p className="line">
            Non, tu as besoin d’une méthode, construite autour de 3 piliers et
            prouvée scientifiquement,
          </p>

          <p className="line">La méthode M.E.N, pour :</p>
          <div className="line orange">
            <p>Mental, Entraînement, et Nutrition</p>
          </div>
          <div className="line ">
            <p>
              Qui va te permettre de perdre du poids ou de prendre de la masse,{" "}
              <span className="underline">
                <strong>en totale autonomie.</strong>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
