import React from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../../images/TwentyEightDays/Logo-Alphabody-White.png";
import "./style.scss";

const Questionnaire = () => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = React.useState(false);
  const [searchParams] = useSearchParams();
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [tabError, setTabError] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [trySubmit, setTrySubmit] = React.useState(false);
  const [inputEmailActiv, setInputEmailActiv] = React.useState(false);
  const [inputs, setInputs] = React.useState({
    //santé
    poids_taille: "",
    satisfait_physique: "",
    objectifs_1_an: "",
    //argent
    entrepreneur: "",
    domaine_entreprise: "",
    service_entreprise: "",
    CA_90_jours: "",
    satisfait_CA: "",
    objectif_CA: "",
    //relation
    situation: "",
    temps_situation: "",
    satisfait_com_couple: "",
    satisfait_com_relations: "",
    enfants: "",
    equilibre: "",
    //mental
    heureux: "",
    maitrise: "",
    croyances: "",
    alignement: "",
    rituel: "",
    deprime: "",
    evolution_mental: "",
    //general
    objectif_immersion: "",
    merite: "",
    convaincre: "",
    essentiel: "",
    investissement: "",
    client: "",
    entendu: "",

    nom: "",
    prenom: "",
    tel: "",
    email: "",
  });

  React.useEffect(() => {
    console.log("form", searchParams.get("form"));
    if (searchParams.get("form") === null || searchParams.get("form") === "false") {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  }, []);

  React.useEffect(() => {
    console.log("email", searchParams.get("email"));
    if (searchParams.get("email") === null) {
      setInputEmailActiv(true);
    } else {
      setInputs({
        ...inputs,
        email: searchParams.get("email"),
      });
    }
  }, []);

  const submit = async () => {
    console.log("INPUTS", inputs);
    setTrySubmit(true);
    if (await verifChamps()) {
      console.log("SUBMIT OK");
      sendSubmit();
    }
  };

  const verifChamps = async () => {
    setTabError([]);
    //verif des inputs
    let findError = false;
    let temp = [];
    for (const property in inputs) {
      if (inputs[property] === "") {
        temp.push(property);
        findError = true;
      }
    }
    //si erreurs affichage
    if (findError) {
      console.log("champs manquant", temp);
      setTabError([...temp]);
      setError(true);
      setMsg("Merci de renseigner tous les champs");
      return false;
    } else {
      setTabError([]);
      setError(false);
      return true;
    }
  };

  const sendSubmit = async () => {
    var options = {
      method: "POST",
      url: "https://hook.integromat.com/nxjaxu5c9i2pigk3zfyxkm6unptd8isu",
      headers: { "Content-Type": "application/json" },
      data: {
        ...inputs,
      },
    };

    const send = await axios
      .request(options)
      .then(function (response) {
        console.log("sendSubmit", response.data);
        return response.data;
      })
      .catch(function (error) {
        console.error("sendSubmitError", error);
      });
    console.log(send);
    if (send === "Accepted") {
      setError(false);
      goNextPage();
    } else {
      setError(true);
      setMsg(
        "Une erreur d'envoi est survenue, merci de recommencer d'ici quelques minutes"
      );
    }
  };

  const goNextPage = () => {
    navigate("/immersion/merci");
  };

  React.useEffect(() => {
    if (
      inputs.situation === "Célibataire" &&
      inputs.satisfait_com_couple !== "N/A"
    ) {
      setInputs({
        ...inputs,
        satisfait_com_couple: "N/A",
      });
    }
    if (
      inputs.situation !== "Célibataire" &&
      inputs.satisfait_com_couple === "N/A"
    ) {
      setInputs({
        ...inputs,
        satisfait_com_couple: "",
      });
    }
    if (trySubmit) {
      verifChamps();
    }
  }, [inputs]);

  return (
    <div className="Immersionformctn">
      <div className="logoCtn">
        <img className="logo" src={Logo} alt="logo" width={200} />
      </div>

      {!showForm && (
        <>
          <div>
            <h2 className="titleForm">
              “Ce documentaire de 5 minutes est bouleversant, émouvant, à couper
              le souffle… À voir <span className="uppercase">absolument</span>{" "}
              par tous les hommes voulant se dépasser.”
            </h2>
          </div>

          <div className="videoWrapper">
            <iframe
              src="https://player.vimeo.com/video/761762645?h=3487462b59&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className="video"
              frameBorder="0"
              allow="autoplay; picture-in-picture"
              allowFullScreen
              title="IMMERSION22 TRAILER5MIN30"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
          <button className="btn uppercase" onClick={() => setShowForm(true)}>
            Clique ici pour Postuler à la Prochaine Immersion AlphaBody
          </button>
        </>
      )}

      {showForm && (
        <div className="content">
          {/* -------------------------------  SECTION SANTE  ------------------------------ */}

          <section className="sante">
            <h1 className="titleSante">Santé</h1>

            <div
              className={
                tabError.find((el) => el === "poids_taille")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Quels sont ton poids (en kg) de corps et ta taille (en cm)
                actuels? <span className="redStar">*</span>
              </p>
              <input
                className="formInput"
                type="text"
                value={inputs.poids_taille}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    poids_taille: e.target.value,
                  })
                }
              />
            </div>

            <div
              className={
                tabError.find((el) => el === "satisfait_physique")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Es-tu satisfait de ton physique actuel?{" "}
                <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn btnRadioCtn-sante">
                <input
                  className="sante"
                  name="sante"
                  id="sante-a"
                  type="radio"
                  checked={inputs.satisfait_physique === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      satisfait_physique: "Oui",
                    })
                  }
                />
                <label htmlFor="sante-a">Oui</label>
                <input
                  className="sante"
                  name="sante"
                  id="sante-b"
                  type="radio"
                  checked={inputs.satisfait_physique === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      satisfait_physique: "Non",
                    })
                  }
                />
                <label htmlFor="sante-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "objectifs_1_an")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Quels sont tes objectifs physiques à 1 an?{" "}
                <span className="redStar">*</span>
              </p>
              <textarea
                className="formTextArea"
                type="text"
                value={inputs.objectifs_1_an}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    objectifs_1_an: e.target.value,
                  })
                }
              />
            </div>
          </section>

          {/* -------------------------------  SECTION ARGENT  ------------------------------ */}

          <section className="argent">
            <h1>Argent</h1>

            <div
              className={
                tabError.find((el) => el === "entrepreneur")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Es-tu un entrepreneur, chef d’entreprise, “à ton compte” qui
                contrôle directement sa rémunération?{" "}
                <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  className="activite"
                  name="activite"
                  id="activite-a"
                  type="radio"
                  checked={inputs.entrepreneur === "Oui"}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      entrepreneur: "Oui",
                    })
                  }
                />
                <label htmlFor="activite-a">Oui</label>
                <input
                  className="activite"
                  name="activite"
                  id="activite-b"
                  type="radio"
                  checked={inputs.entrepreneur === "Non"}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      entrepreneur: "Non",
                    })
                  }
                />
                <label htmlFor="activite-b">Non</label>
              </div>
            </div>
            <div
              className={
                tabError.find((el) => el === "domaine_entreprise")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              {inputs.entrepreneur === "Oui" ? (
                <p className="question">
                  Dans quel domaine d’activité se situe ton entreprise?{" "}
                  <span className="redStar">*</span>
                </p>
              ) : (
                <p className="question">
                  Dans quel domaine d'activité se situe l'entreprise où tu
                  travailles? <span className="redStar">*</span>
                </p>
              )}
              <input
                className="formInput"
                type="text"
                value={inputs.domaine_entreprise}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    domaine_entreprise: e.target.value,
                  })
                }
              />
            </div>

            <div
              className={
                tabError.find((el) => el === "service_entreprise")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              {inputs.entrepreneur === "Oui" ? (
                <p className="question">
                  Quel est le service ou produit de ton entreprise?{" "}
                  <span className="redStar">*</span>
                </p>
              ) : (
                <p className="question">
                  Quel est le service ou produit de ton entreprise?{" "}
                  <span className="redStar">*</span>
                </p>
              )}
              <input
                className="formInput"
                type="text"
                value={inputs.service_entreprise}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    service_entreprise: e.target.value,
                  })
                }
              />
            </div>

            <div
              className={
                tabError.find((el) => el === "CA_90_jours")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              {inputs.entrepreneur === "Oui" ? (
                <p className="question">
                  Quel est ton chiffre d’affaires cumulé sur les 90 derniers
                  jours? <span className="redStar">*</span>
                </p>
              ) : (
                <p className="question">
                  Quel est ton dernier salaire?{" "}
                  <span className="redStar">*</span>
                </p>
              )}
              <input
                className="formInput"
                type="text"
                value={inputs.CA_90_jours}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    CA_90_jours: e.target.value,
                  })
                }
              />
            </div>

            <div
              className={
                tabError.find((el) => el === "satisfait_CA")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              {inputs.entrepreneur === "Oui" ? (
                <p className="question">
                  Es-tu satisfait de ton chiffre d'affaires actuel?{" "}
                  <span className="redStar">*</span>
                </p>
              ) : (
                <p className="question">
                  Es-tu satisfait de ton salaire actuel?{" "}
                  <span className="redStar">*</span>
                </p>
              )}
              <div className="btnRadioCtn">
                <input
                  name="ca"
                  className="ca"
                  id="ca-a"
                  type="radio"
                  checked={inputs.satisfait_CA === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      satisfait_CA: "Oui",
                    })
                  }
                />
                <label htmlFor="ca-a">Oui</label>
                <input
                  name="ca"
                  className="ca"
                  id="ca-b"
                  type="radio"
                  checked={inputs.satisfait_CA === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      satisfait_CA: "Non",
                    })
                  }
                />
                <label htmlFor="ca-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "objectif_CA")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              {inputs.entrepreneur === "Oui" ? (
                <p className="question">
                  Quel est ton objectif de chiffre d'affaires sur les 90
                  prochains jours? <span className="redStar">*</span>
                </p>
              ) : (
                <p className="question">
                  Quel est ton objectif de salaire sur les prochains mois?{" "}
                  <span className="redStar">*</span>
                </p>
              )}
              <input
                className="formInput"
                type="text"
                value={inputs.objectif_CA}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    objectif_CA: e.target.value,
                  })
                }
              />
            </div>
          </section>

          {/* -------------------------------  SECTION RELATION  ------------------------------ */}

          <section className="relation">
            <h1>Relation</h1>

            <div
              className={
                tabError.find((el) => el === "situation")
                  ? "formPartial formRelation errorInput"
                  : "formPartial formRelation"
              }
            >
              <p className="question">
                Es-tu célibataire, marié ou en couple ?{" "}
                <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="situation"
                  className="situation"
                  id="situation-a"
                  type="radio"
                  checked={inputs.situation === "Célibataire"}
                  onChange={() => {
                    setInputs({
                      ...inputs,
                      situation: "Célibataire",
                    });
                  }}
                />
                <label id="situationLabel" htmlFor="situation-a">
                  Célibataire
                </label>
                <input
                  name="situation"
                  className="situation"
                  id="situation-b"
                  type="radio"
                  checked={inputs.situation === "Marié-Pacsé"}
                  onChange={() => {
                    setInputs({
                      ...inputs,
                      situation: "Marié-Pacsé",
                    });
                  }}
                />
                <label id="situationLabel" htmlFor="situation-b">
                  Marié / Pacsé
                </label>
                <input
                  name="situation"
                  className="situation"
                  id="situation-c"
                  type="radio"
                  checked={inputs.situation === "En couple"}
                  onChange={() => {
                    setInputs({
                      ...inputs,
                      situation: "En couple",
                    });
                  }}
                />
                <label id="situationLabel" htmlFor="situation-c">
                  En couple
                </label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "temps_situation")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Depuis combien de temps? <span className="redStar">*</span>
              </p>
              <input
                className="formInput"
                type="text"
                value={inputs.temps_situation}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    temps_situation: e.target.value,
                  })
                }
              />
            </div>
            {inputs.situation !== "Célibataire" && (
              <>
                <div
                  className={
                    tabError.find((el) => el === "satisfait_com_couple")
                      ? "formPartial errorInput"
                      : "formPartial"
                  }
                >
                  <p className="question">
                    Es-tu satisfait de la communication dans ton couple?{" "}
                    <span className="redStar">*</span>
                  </p>
                  <div className="btnRadioCtn">
                    <input
                      name="couple"
                      className="couple"
                      id="couple-a"
                      type="radio"
                      checked={inputs.satisfait_com_couple === "Oui"}
                      onChange={() =>
                        setInputs({
                          ...inputs,
                          satisfait_com_couple: "Oui",
                        })
                      }
                    />
                    <label htmlFor="couple-a">Oui</label>
                    <input
                      name="couple"
                      className="couple"
                      id="couple-b"
                      type="radio"
                      checked={inputs.satisfait_com_couple === "Non"}
                      onChange={() =>
                        setInputs({
                          ...inputs,
                          satisfait_com_couple: "Non",
                        })
                      }
                    />
                    <label htmlFor="couple-b">Non</label>
                  </div>
                </div>
              </>
            )}
            <div
              className={
                tabError.find((el) => el === "satisfait_com_relations")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Es-tu satisfait de ta communication avec tes amis, famille,
                associés ou collègues? <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="communication"
                  className="communication"
                  id="communication-a"
                  type="radio"
                  checked={inputs.satisfait_com_relations === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      satisfait_com_relations: "Oui",
                    })
                  }
                />
                <label htmlFor="communication-a">Oui</label>
                <input
                  name="communication"
                  className="communication"
                  id="communication-b"
                  type="radio"
                  checked={inputs.satisfait_com_relations === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      satisfait_com_relations: "Non",
                    })
                  }
                />
                <label htmlFor="communication-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "enfants")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                As-tu des enfants? <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="enfant"
                  className="enfant"
                  id="enfant-a"
                  type="radio"
                  checked={inputs.enfants === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      enfants: "Oui",
                    })
                  }
                />
                <label htmlFor="enfant-a">Oui</label>
                <input
                  name="enfant"
                  className="enfant"
                  id="enfant-b"
                  type="radio"
                  checked={inputs.enfants === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      enfants: "Non",
                    })
                  }
                />
                <label htmlFor="enfant-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "equilibre")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Comment noterais-tu ton{" "}
                <span className="uppercase">équilibre</span> de vie entre vie
                pro et vie perso? (1= NUL -- 10= GENIAL){" "}
                <span className="redStar">*</span>
              </p>
              <div className="selectCtn">
                <select
                  type="text"
                  value={inputs.equilibre}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      equilibre: e.target.value,
                    })
                  }
                >
                  <option value="">Sélectionne la valeur</option>
                  {numbers.map((number) => {
                    return (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </section>

          {/* -------------------------------  SECTION MENTAL  ------------------------------ */}

          <section className="mental">
            <h1>Mental</h1>

            <div
              className={
                tabError.find((el) => el === "heureux")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Es-tu heureux aujourd’hui? <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="heureux"
                  className="heureux"
                  id="heureux-a"
                  type="radio"
                  checked={inputs.heureux === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      heureux: "Oui",
                    })
                  }
                />
                <label htmlFor="heureux-a">Oui</label>
                <input
                  name="heureux"
                  className="heureux"
                  id="heureux-b"
                  type="radio"
                  checked={inputs.heureux === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      heureux: "Non",
                    })
                  }
                />
                <label htmlFor="heureux-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "maitrise")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Maîtrises-tu tes habitudes et ta discipline?{" "}
                <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="discipline"
                  className="discipline"
                  id="discipline-a"
                  type="radio"
                  checked={inputs.maitrise === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      maitrise: "Oui",
                    })
                  }
                />
                <label htmlFor="discipline-a">Oui</label>
                <input
                  name="discipline"
                  className="discipline"
                  id="discipline-b"
                  type="radio"
                  checked={inputs.maitrise === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      maitrise: "Non",
                    })
                  }
                />
                <label htmlFor="discipline-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "croyances")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                As-tu des croyances limitantes?{" "}
                <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="croyances"
                  className="croyances"
                  id="croyances-a-b"
                  type="radio"
                  checked={inputs.croyances === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      croyances: "Oui",
                    })
                  }
                />
                <label htmlFor="croyances-a-b">Oui</label>
                <input
                  name="croyances"
                  className="croyances"
                  id="croyances-b"
                  type="radio"
                  checked={inputs.croyances === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      croyances: "Non",
                    })
                  }
                />
                <label htmlFor="croyances-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "alignement")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Te sens-tu aligné avec tes valeurs?{" "}
                <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="valeurs"
                  className="valeurs"
                  id="valeurs-a"
                  type="radio"
                  checked={inputs.alignement === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      alignement: "Oui",
                    })
                  }
                />
                <label htmlFor="valeurs-a">Oui</label>
                <input
                  name="valeurs"
                  className="valeurs"
                  id="valeurs-b"
                  type="radio"
                  checked={inputs.alignement === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      alignement: "Non",
                    })
                  }
                />
                <label htmlFor="valeurs-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "rituel")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Fais-tu un rituel matinal régulièrement?{" "}
                <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="rituel"
                  className="rituel"
                  id="rituel-a"
                  type="radio"
                  checked={inputs.rituel === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      rituel: "Oui",
                    })
                  }
                />
                <label htmlFor="rituel-a">Oui</label>
                <input
                  name="rituel"
                  className="rituel"
                  id="rituel-b"
                  type="radio"
                  checked={inputs.rituel === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      rituel: "Non",
                    })
                  }
                />
                <label htmlFor="rituel-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "deprime")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                As-tu des passages de tristesse, déprime, anxiété ou autre?{" "}
                <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="passages"
                  className="passages"
                  id="passages-a"
                  type="radio"
                  checked={inputs.deprime === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      deprime: "Oui",
                    })
                  }
                />
                <label htmlFor="passages-a">Oui</label>
                <input
                  name="passages"
                  className="passages"
                  id="passages-b"
                  type="radio"
                  checked={inputs.deprime === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      deprime: "Non",
                    })
                  }
                />
                <label htmlFor="passages-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "evolution_mental")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Comment voudrais-tu évoluer mentalement?{" "}
                <span className="redStar">*</span>
              </p>
              <textarea
                className="formTextArea"
                type="text"
                value={inputs.evolution_mental}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    evolution_mental: e.target.value,
                  })
                }
              />
            </div>
          </section>

          {/* -------------------------------  SECTION GENERAL  ------------------------------ */}

          <section className="general">
            <h1>Général</h1>

            <div
              className={
                tabError.find((el) => el === "objectif_immersion")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Que cherches-tu à obtenir de l'immersion?{" "}
                <span className="redStar">*</span>
              </p>
              <textarea
                className="formTextArea"
                type="text"
                value={inputs.objectif_immersion}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    objectif_immersion: e.target.value,
                  })
                }
              />
            </div>

            <div
              className={
                tabError.find((el) => el === "merite")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Pourquoi es-tu la personne qui mérite le plus de faire cette
                immersion? <span className="redStar">*</span>
              </p>
              <textarea
                className="formTextArea"
                type="text"
                value={inputs.merite}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    merite: e.target.value,
                  })
                }
              />
            </div>

            <div
              className={
                tabError.find((el) => el === "convaincre")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Convaincs-moi que cette immersion ne va pas juste être un
                événement parmi tant d’autres et que tu vas avoir de{" "}
                <span className="uppercase">vrais</span> résultats.{" "}
                <span className="redStar">*</span>
              </p>
              <textarea
                className="formTextArea"
                type="text"
                value={inputs.convaincre}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    convaincre: e.target.value,
                  })
                }
              />
            </div>

            <div
              className={
                tabError.find((el) => el === "essentiel")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Pourquoi est-il absolument essentiel que tu{" "}
                <span className="uppercase">transformes</span>{" "}
                <span className="uppercase">ta vie</span>{" "}
                <span className="uppercase">maintenant</span>?{" "}
                <span className="redStar">*</span>
              </p>
              <textarea
                className="formTextArea"
                type="text"
                value={inputs.essentiel}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    essentiel: e.target.value,
                  })
                }
              />
            </div>

            <div
              className={
                tabError.find((el) => el === "investissement")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                En admettant que tu es sélectionné parmi les centaines de
                candidats, es-tu prêt à investir une somme importante (4000€)
                dans ton développement personnel?{" "}
                <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="investir"
                  className="investir"
                  id="investir-a"
                  type="radio"
                  checked={inputs.investissement === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      investissement: "Oui",
                    })
                  }
                />
                <label htmlFor="investir-a">Oui</label>
                <input
                  name="investir"
                  className="investir"
                  id="investir-b"
                  type="radio"
                  checked={inputs.investissement === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      investissement: "Non",
                    })
                  }
                />
                <label htmlFor="investir-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "client")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Es-tu déjà client du programme AlphaBody?{" "}
                <span className="redStar">*</span>
              </p>
              <div className="btnRadioCtn">
                <input
                  name="client"
                  className="client"
                  id="client-a"
                  type="radio"
                  checked={inputs.client === "Oui"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      client: "Oui",
                    })
                  }
                />
                <label htmlFor="client-a">Oui</label>
                <input
                  name="client"
                  className="client"
                  id="client-b"
                  type="radio"
                  checked={inputs.client === "Non"}
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      client: "Non",
                    })
                  }
                />
                <label htmlFor="client-b">Non</label>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "entendu")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Comment as-tu entendu parlé de nous ?{" "}
                <span className="redStar">*</span>
              </p>
              <input
                className="formInput"
                type="text"
                value={inputs.entendu}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    entendu: e.target.value,
                  })
                }
              />
            </div>

            <div
              className={
                tabError.find((el) => el === "nom") ||
                  tabError.find((el) => el === "prenom")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Ton nom <span className="redStar">*</span>
              </p>
              <div className="nameInfosCtn">
                <div className="nameInfos">
                  <input
                    className="formInput"
                    type="text"
                    value={inputs.prenom}
                    onChange={(e) =>
                      setInputs({
                        ...inputs,
                        prenom: e.target.value,
                      })
                    }
                  />
                  <span>Prénom</span>
                </div>
                <div className="nameInfos">
                  <input
                    className="formInput"
                    type="text"
                    value={inputs.nom}
                    onChange={(e) =>
                      setInputs({
                        ...inputs,
                        nom: e.target.value,
                      })
                    }
                  />
                  <span>Nom</span>
                </div>
              </div>
            </div>

            <div
              className={
                tabError.find((el) => el === "tel")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Ton numéro de téléphone <span className="redStar">*</span>
              </p>
              <input
                className="formInput"
                type="number"
                value={inputs.tel}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    tel: e.target.value,
                  })
                }
              />
            </div>

            <div
              className={
                tabError.find((el) => el === "email")
                  ? "formPartial errorInput"
                  : "formPartial"
              }
            >
              <p className="question">
                Ton email <span className="redStar">*</span>
              </p>
              {inputEmailActiv ? (
                <input
                  className="formInput"
                  type="text"
                  value={inputs.email}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      email: e.target.value,
                    })
                  }
                />
              ) : (
                <>{inputs.email}</>
              )}
            </div>
          </section>
          {error && <span className="errorForm">{msg}</span>}
          <div >
            <button
              className="btn uppercase"
              type="submit" onClick={() => submit()}>
              Je m'inscris à la liste d'attente
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Questionnaire;
