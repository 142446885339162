
import * as tgm from './googleTagManager'
import * as ttk from './ticktokPixel'
import * as snaptr from './snapchat'
import * as fbq from './fpixel'
import * as ga from './googleAds'

// vue des articles
export const viewItems = (data) => {

  tgm.viewItems(data)

}

// ajout au panier
export const addToCart = (data) => {

  tgm.addToCart(data)
  ttk.addToCart(data)
  snaptr.addToCart(data)
  fbq.addToCart(data)
  ga.addToCart(data)

}
