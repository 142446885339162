import ThankYou from "../../components/Immersion/ThankYou";
import Footer from "../../components/Immersion/Footer";

const Merci = () => {
  return (
    <>
      <ThankYou />
      <Footer />
    </>
  );
};

export default Merci;
