import Button from "../Button";
import image from "../../../images/TwentyEightDays/nico-av.png";
import "./style.scss";

const Section8 = () => {
  return (
    <div className="containerSection8">
      <div className="content">
        <div className="container-title">
          <div className="center">
            <h2 className="bg-grey">VOTRE FORMATEUR</h2>
            <h1 className="grey">
              <strong>NICOLAS SILVY</strong>
            </h1>
          </div>
        </div>

        <div className="container">
          <div className="text bg-flou">
            <p className="grey">
              Avant de devenir expert en transformation physique,
            </p>
            <p className="grey">
              <strong>Nicolas a essayé des dizaines de méthodes</strong> <br />
              de perte de poids, de sèche, de cardio, de programmes en
              musculation sans vraiment arriver aux résultats voulus.
            </p>
            <p className="grey">
              Il était prêt à acheter tous les compléments du monde pour lui
              permettre d’arriver à devenir
              <br />
              <strong>sec et musclé, sans passer des heures à la salle</strong>
              <br />
              car il n’avait tout simplement pas le temps pour ça.
            </p>
            <p className="grey">
              C’est en 2016 qu’il trouva le secret qui allait lui permettre de
              développer une
              <br />
              <strong>technique rapide et ultra efficace</strong>
              <br />
              pour obtenir le corps qu’il souhaitait en un minimum de temps à
              faire du sport.
            </p>

            <p className="grey">
              Depuis, il a fondé <strong>ALPHABODY</strong> afin de permettre à
              toutes et à tous de retrouver
              <br />
              <strong>
                confiance en leur corps et de profiter de la vie sans se priver.
              </strong>
            </p>

            <p className="grey">
              Actuellement, une vingtaine de coachs ont été formés aux concepts
              d’Alphabody et ensemble, ils ont accompagné plus de 10 000 hommes
              et de femmes de 18 à 76 ans pour qu’ils deviennent la meilleure
              version d’eux même, qu’ils en soient fiers, et qu’ils puissent se
              sentir mieux dans leur corps et leur vie.
            </p>

            <p className="grey">
              Nicolas a testé en premier le challenge des 28 jours afin de te
              garantir les résultats et ceux-ci sont allés même
              <br />
              <strong>au-delà de ses espérances.</strong>
              <br /> Vois toi-même :
            </p>
            <h3 className="bg-grey">
              <strong>C’est juste extraordinaire !</strong>
            </h3>
          </div>
          <div className="image">
            <img src={image} alt="nico" />
          </div>
        </div>
        <Button section="section-8" />
      </div>
    </div>
  );
};

export default Section8;
