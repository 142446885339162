import "./style.scss";

const Section2 = () => {
  return (
    <div className="containerSection2">

      <div className="content">
        <h3 className="grey bg-grey">
          <strong>Tu te dis certainement que c’est un mythe, </strong>
        </h3>
        <p className="grey">
          que ça ne peut pas être possible de perdre du poids sans manger ce qui te fait plaisir ou sans passer des heures à courir et à transpirer des pieds à la tête.
        </p>
        <p className="grey">
          Tu vas peut-être même penser que des résultats pareils ne sont pas atteignables en si peu de temps et pas avec ta morphologie.
        </p>

        <h3 className="grey">
          <strong>Mais, voici ce que personne n’a encore osé te dire jusqu’ici</strong>
        </h3>

        <p className="grey">
          La méthode que je suis sur le point de te révéler, peu de gens dans le domaine du fitness en parlent. Pourquoi ? Pour la simple et bonne raison que ça ne leur fait pas gagner d’argent, ça ne leur rapporte aucun client et pourtant, c'est bénéfique pour la santé.
        </p>
        <p className="grey">
          Sais-tu que la plupart des régimes alimentaires sont conçus pour te faire perdre du poids rapidement (muscles y compris) ?
        </p>
        <p className="grey">
          <strong>Et ils y arrivent !</strong> En te privant de tous les aliments que tu aimes et de toutes interactions sociales qui nuiraient à ces fameux régimes : apéros avec tes amis, dîners et restaurants en famille, en amoureux…
        </p>
        <p className="grey">
          Ces régimes te permettent d’avoir un résultat certes, mais un résultat <strong>TEMPORAIRE !</strong>
        </p>
        <p className="grey">
          Tu as certainement déjà essayé les régimes comme :<br />
          <strong>Les régimes protéinés, Paléo, Cétogène, WEIGHT WATCHERS, Dukan, Mayo… </strong><br />
          Pour ne citer qu’eux.
        </p>
        <p className="grey">
          Et si tu es là aujourd’hui à lire ces lignes, c’est que tu as toi-même constaté ce dont je te parle.
        </p>
        <p className="grey">
          Tu as eu beau suivre ces Diet à la lettre, sans faire le moindre écart et pourtant ce que tu avais si difficilement gagné, grâce à des sacrifices quotidiens, s’estompe au fur et à mesure que tu retrouves ton rythme de vie normal. Les kilos perdus reviennent, tes muscles disparaissent et ton corps revient à son même aspect qu’au départ, voire parfois pire qu’avant de commencer.
        </p>

      </div>
    </div >
  );
};

export default Section2;
