import React from "react";
import "./style.scss";
const CookiesBar = () => {

  const [cookiesDisplay, setCookiesDiplay] = React.useState(true)

  return (
    <div className="cookiesCtn">
      {
        cookiesDisplay &&
        <div className="content">
          <p className="white text-small">
            Nous utilisons des cookies pour nous permettre de mieux comprendre
            comment le site est utilisé. <br />
            En continuant à utiliser ce site, vous
            acceptez cette politique.
          </p>
          <button className="btn"
            onClick={() => { setCookiesDiplay(false) }}
          >
            J'accepte
          </button>
        </div>
      }
    </div>
  );
};

export default CookiesBar;
