const TIKTOK_PIXEL = 'C8L0K0KO0T91FSTH3JKG';

export const addToCart = (data) => {
  console.log('data:', data)
  if (window.ttq) {
    for (const item of data.items) {
      window.ttq.track('AddToCart', {
        quantity: item.quantity,
        content_id: item.item_id,
        content_name: item.item_name,
        currency: "EUR",
        value: data.value,
        price: item.price
      })
    }
  }
};
