/* eslint-disable jsx-a11y/anchor-is-valid */
import "./style.scss";

const Section4 = () => {
  return (
    <div className="container7dcSection4">
      <div className="content">
        <div className="container-text">
          <h2 className="grey titleSection4">
            <strong>
              Et voici concrètement ce que tu vas apprendre pendant les 7
              prochains jours :
            </strong>
          </h2>

          <div className="line">
            <span className="check">✔</span>
            <p>
              <strong>J1</strong> = Les 3 règles simples à suivre au quotidien,
              pour t’alimenter correctement et donner à ton corps ce dont il a
              vraiment besoin
            </p>
          </div>

          <div className="line">
            <span className="check">✔</span>
            <p>
              <strong>J2</strong> = Comment perdre du poids grâce au déficit
              calorique
            </p>
          </div>

          <div className="line">
            <span className="check">✔</span>
            <p>
              <strong>J3</strong> = Pourquoi les mythes sur la perte de poids te
              font en réalité grossir, et la seule chose que tu dois vraiment
              savoir pour perdre du poids
            </p>
          </div>

          <div className="line">
            <span className="check">✔</span>
            <p>
              <strong>J4</strong> = Comment éviter les 3 erreurs que tout le
              monde fait et qui t’empêchent d’atteindre tes objectifs
            </p>
          </div>

          <div className="line">
            <span className="check">✔</span>
            <p>
              <strong>J5</strong> = 1er pilier, le mental. Comment ton entourage
              peut te faire prendre ou perdre du poids
            </p>
          </div>

          <div className="line">
            <span className="check">✔</span>
            <p>
              <strong>J6</strong> = 2ème pilier, l’entraînement. Comment
              t’entrainer pour perdre du poids et prendre du muscle, en moins de
              3 heures par semaine
            </p>
          </div>

          <div className="line">
            <span className="check">✔</span>
            <p>
              <strong>J7</strong> = 3ème pilier, la nutrition, et comment avoir
              le corps de tes rêves sans avoir besoin de te priver
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
