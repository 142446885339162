import Questionnaire from "../../components/Immersion/Questionnaire";
import Footer from "../../components/Immersion/Footer";

const Form = () => {
  return (
    <>
      <Questionnaire />
      <Footer />
    </>
  );
};

export default Form;
