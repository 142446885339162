import "./style.scss";
import image from '../../../images/TwentyEightDays/down-3.png'
import nutri from '../../../images/TwentyEightDays/nutrition.png'
import sport from '../../../images/TwentyEightDays/sport.png'
import motivation from '../../../images/TwentyEightDays/motivation.png'
import Button from "../Button";

const Section7 = () => {
  return (
    <div className="containerSection7">
      <div className="content">

        <h1 className="bg-white upperCase">
          Ok, alors qu’y a-t-il dans ce fameux<br />
          <strong>
            <span className="orange">Challenge de 28 JOURS :</span>
          </strong>
        </h1>

        <h2 className="white">
          <strong>
            Chez Alphabody, nous mettons un point d’honneur autour de ces 3 piliers :
          </strong>
        </h2>

        <div className="container-card">
          <div className="card">
            <h3 className="grey">
              <strong>
                La Nutrition
              </strong>
            </h3>
            <img src={nutri} alt="nutrition" />
            <p className="grey">
              afin qu’elle soit optimisée pour chacun de nos coachés et accélère la perte de gras au maximum.
            </p>
          </div>
          <div className="card">
            <h3 className="grey">
              <strong>
                Le Sport
              </strong>
            </h3>
            <img src={sport} alt="sport" />
            <p className="grey">
              les entraînements sportifs vont accentuer ta perte de gras et augmenter ta masse musculaire.
            </p>
          </div>
          <div className="card">
            <h3 className="grey">
              <strong>
                La Motivation
              </strong>
            </h3>
            <img src={motivation} alt="motivation" />
            <p className="grey">
              l’état d’esprit adéquat qu’il te faudra pour tenir sur le long terme et réussir à atteindre tes objectifs.
            </p>
          </div>
        </div>

        <h1 className="white upperCase">
          Ainsi dans notre Challenge Best-Seller, le plus vendu, tu retrouveras :
        </h1>
        <div className="image">
          <img src={image} alt="down-arrow" />
        </div>

        <div className="card-week">
          <h3 className="grey">
            <strong>
              Semaine 1 :
            </strong>
          </h3>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              Tu verras l’importance de créer une vision board et comment la construire pour atteindre tes objectifs.
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              La pyramide Alphabody et toutes les fausses croyances que tu peux avoir dans le sport et la nutrition qui t’empêchent actuellement d’avoir des résultats sur le long terme.
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              Ton programme nutritionnel adapté selon ton genre, ton nombre de kcal et même selon ton régime alimentaire (sans gluten, végan…) ainsi que des conseils pour perdre de la graisse efficacement sans perdre de muscles.
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              Ton programme de musculation, pour hommes et pour femme. Tu auras accès également à un document expliquant comment gérer ta progression
            </p>
          </div>
          <strong>En bonus:</strong>
          <p className="grey">
            un fichier pour planifier tes séances, suivre ta progression, ton niveau, tes heures de sommeil…<br />
            Bref, tout pour te permettre de t’y tenir et de réussir ce challenge.
          </p>

        </div>
        <div className="card-week">
          <h3 className="grey">
            <strong>
              Semaine 2 :
            </strong>
          </h3>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              On te dévoile la technique la plus efficace pour rester toujours motivé sans jamais lâcher
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              Comment le sommeil peut accroître tes performances et ta perte de poids.
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              L’importance des protéines et comment vraiment les utiliser à bon escient pour être en bonne santé et avoir un corps musclé
            </p>
          </div>
        </div>
        <div className="card-week">
          <h3 className="grey">
            <strong>
              Semaine 3 :
            </strong>
          </h3>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              Comment augmenter l’amour que tu te portes grâce à l’autodiscipline
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              Comment perdre du gras sans faire de cardio. Tu vas pouvoir enfin tout comprendre sur le cardio
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              Bien utiliser la micro-nutrition pour ne plus jamais être en surpoids, garder un corps musclé, tonique toute l’année sans te priver et tu verras une façon d’optimiser ton absorption en vitamines pour aider ton corps à se dessiner et rester en bonne santé.
            </p>
          </div>
        </div>
        <div className="card-week">
          <h3 className="grey">
            <strong>
              Semaine 4 :
            </strong>
          </h3>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              Comment garder tes résultats et aller plus loin encore
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              Comment continuer à construire ton physique sur la durée, sur 12 mois et plus encore
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p className="grey">
              Acter ta transformation dans le temps, dans tes habitudes pour manger plus et devenir plus Fit. La solution pour ne plus regrossir et garder la ligne après ce challenge de 28 jours.
            </p>
          </div>
        </div>
        <div>
          <p className="white">
            Ces 12 modules vidéo te donneront l’ensemble
            des connaissances de base pour non seulement réussir ce challenge<br />
            mais également pour devenir autonome dans ton alimentation afin de<br />
            sans trop de gras et surtout
          </p>
          <p className="white upperCase">
            <strong> garder ton corps musclé, dessiné,</strong>
          </p>
          <p className="white">
            sans trop de gras et surtout
          </p>

          <p className="white upperCase">
            <strong>sans plus jamais te priver.</strong>
          </p>
        </div>
        <h2 className="white">
          Tu pourras voir et revoir ces vidéos <strong className="black">24h/24 et 7j/7</strong> autant de fois que tu le veux et ce,<br />
          <strong className="black">pour toute ta vie.</strong>
        </h2>
        <h2 className="white">
          Tu n’auras plus jamais d’excuses pour ne pas réussir à
        </h2>
        <h2 className="black">
          <strong className="upperCase">obtenir le corps de tes rêves.</strong>
        </h2>
        <Button section="section-7" bg="white" />
      </div>
    </div >
  );
};

export default Section7;
