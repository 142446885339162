import Button from "../Button";
import image1 from '../../../images/TwentyEightDays/plan.svg'
import image2 from '../../../images/TwentyEightDays/sante.svg'
import "./style.scss";

const Section9 = () => {
  return (
    <div className="containerSection6">

      <div className="content">

        <div className="block">
          <h1 className="bg-grey upperCase">
            <strong>Tout n’est pas perdu. Lis la suite.</strong>
          </h1>
        </div>

        <div className="block left">
          <p className="grey">
            Tu te demandes sûrement alors comment c’est possible de <strong>perdre du poids et même de devenir musclé(e)</strong>, alors que tu vois tant de gens se dépenser en salle de sport et ne pas prendre de dessert pour <strong>rester « Fit »</strong> ?
          </p>
          <p className="grey">
            En ayant vécu la même chose et avec le retour de tous mes coachés, je sais qu’avoir un accompagnement au jour le jour est essentiel pour réussir à atteindre son <strong>objectif</strong>. Et c’est exactement ce que te propose ce <strong>challenge de 28 jours.</strong>
          </p>
          <p className="grey">
            Avec un programme sportif et nutritionnel adapté à tes besoins, tu seras guidé grâce à des conseils en vidéo pour <strong>améliorer ton quotidien</strong> (mindset, sommeil, alimentation, discipline…).
          </p>
          <p className="grey">
            <strong>Et la bonne nouvelle,</strong>
          </p>
          <p className="grey">
            C’est que cette méthode te permettra non seulement d’avoir une nouvelle hygiène de vie pendant 28 jours mais également pour <strong>le reste de ta vie.</strong>
          </p>
        </div>
        <div className="block">
          <h2
            className="grey upperCase"
          >
            <strong>
              <span className="orange underline">Imagine-toi</span>
            </strong>
          </h2>
          <p className="line border-bottom grey">
            <span className="check">✔</span>suivre cette méthode de A à Z, la poursuivre jusqu’au bout, et atteindre les résultats que tu souhaites.
          </p>
          <div className="image">
            <img src={image1} alt="plan" />
          </div>
        </div>
        <div className="block">
          <h2
            className="grey upperCase"
          >
            <strong>
              <span className="orange underline">Imagine-toi</span> pouvoir:
            </strong>
          </h2>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Te sentir fier(e) de toi
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            De savoir exactement quoi manger pour rester tel que tu seras devenu(e)
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Être bien dans ton corps et te regarder avec satisfaction devant ton miroir
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Prendre plaisir à t’habiller et rentrer dans des vêtements que tu avais laissé au placard
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Devenir plus attirant(e), plus sexy que jamais
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Plaire encore plus à ton/ta partenaire (ou de t’en trouver un(e), ça sera beaucoup plus facile avec un physique qui inspire la santé 😉)
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Avoir enfin des pecs et des abdos tracés/ ou un corps plus ferme avec des fessiers plus rebondis
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Avoir plus d’énergie et de ne plus jamais être trop fatigué pour faire une activité sportive
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Avoir une plus grande assurance
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Ne plus avoir peur pour ta santé, pour ton diabète, ton cholestérol trop haut….
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Prendre un nouveau départ dans ta vie tellement tu te sentiras en confiance d’avoir été capable de réussir
          </p>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            Avoir la sensation d’avoir pris une revanche sur ta vie, d’être en contrôle de toi-même, de te sentir aux commandes de ton corps et de ta vie.
          </p>
          <div className="image">
            <img src={image2} alt="sante" />
          </div>
        </div>
        <div className="block ">
          <h2
            className="grey upperCase"
          >
            <strong>
              <span className="orange underline">Imagine-toi</span>
            </strong>
          </h2>
          <p className="line border-bottom grey">
            <span className="check">✔</span>
            avoir une plus haute estime de toi, car tu auras réussi à aller jusqu’au bout et tu sauras qu’il t’est désormais possible de maintenir cette hygiène de vie tout au long de l’année et d’avoir le corps de tes rêves.
          </p>
        </div>
        <div className="block center">

          <p className="grey">
            <strong>
              Mais il faut que tu saches une dernière chose, si tu ne changes pas maintenant, plus tard ça sera pire et encore plus difficile. Le métabolisme change avec le temps et il devient de plus en plus difficile de perdre du gras.
            </strong>
          </p>
          <h3 className="grey">
            <strong>
              Alors es-tu prêt à obtenir des RÉSULTATS <span className="underline orange">Réels</span>, <span className="underline orange">Tangibles</span>, que tu pourras voir devant ton miroir et dans tes vêtements ?
            </strong>
          </h3>
          <h3 className="orange">
            <strong>
              Es-tu prêt à recevoir des compliments sur ta transformation ?
            </strong>
          </h3>

          <Button section="section-6" />
        </div>
      </div>
    </div>
  );
};

export default Section9;
