import React from "react";
import { useLocation } from "react-router-dom";

import CookiesBar from "../../components/General/CookiesBar";
import Footer from "../../components/General/Footer";
import Section1 from '../../components/SevenDaysVsl/Section1'

import * as tracking from "../../utils/tracking";

import "../../styles/index.scss";

const SevenDaysVsl = () => {
  let location = useLocation();

  React.useEffect(() => {
    document.title = "Challenge 7 Jours";

    tracking.viewItems({
      event: "view_item",
      value: 7,
      funnel: "7dc",
      page: location.pathname,
      items: [
        {
          item_name: "Challenge 7 Jours",
          item_id: "7dc",
          price: 7.0,
          quantity: 1,
        },
      ],
    });
  }, []);

  return (
    <>
      <CookiesBar />
      <Section1 />
      <Footer />
    </>
  );
};

export default SevenDaysVsl;
