import "./style.scss";

const Footer = () => {
  return (
    <div className="footerCtn">
      <div className="content">
        <p className="white text-small">
          {`Disclaimer - Les conseils donnés sur ce site proviennent de notre expérience et ne sont pas des conseils médicaux. Ils ne peuvent en aucun cas se substituer à un traitement médical. Toute personne estimant présenter un problème de santé doit consulter un médecin. Les résultats peuvent varier d'un individu à un autre. Toutes les indications sur ce site ne prétendent aucunement être prises pour des indications thérapeutiques, telles que définies par les textes français et européens en vigueur.`}
        </p>
        <p className="white text-small">
          {`ALPHABODY © . Tous Droits Réservés.`}
        </p>
        <div className="links">
          <p>
            <a
              href="https://mentions.alphabody.fr/donnees-privees"
            >{`Politique de confidentialité`}</a>
          </p>
          <p>
            <a
              href="https://mentions.alphabody.fr/conditions-generales-de-ventes"
            >{`Termes & Conditions`}</a>
          </p>
          <p>
            <a
              href="https://mentions.alphabody.fr/mentions-legales"
            >{`Mentions légales`}</a>
          </p>
        </div>
        <p className="white text-small">
          {`NOT FACEBOOK : This site is not a part of the Facebook website or Facebook Inc.`} <br />
          {`Additionally, This site is NOT endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.`}
        </p>
      </div>
    </div >
  );
};

export default Footer;
