import "./style.scss";

const Section7 = () => {
  return (
    <div className="container7dcSection7">
      <div className="content">
        <h3 className="grey">
          Attention, je ne suis pas en train de te dire que je vais te
          transformer en The Rock ou Jennifer Aniston en seulement une semaine.
        </h3>
        <br />
        <h3 className="grey">
          Grâce au 7 Days Challenge, tu vas apprendre comment perdre du poids ou
          prendre de la masse en autonomie,
        </h3>
        <br />
        <h3 className="grey">
          <strong>
            Et surtout, tu vas obtenir tes premiers résultats et perdre tes
            premiers kilos en 7 jours, pour seulement 7€ !
          </strong>
        </h3>
      </div>
    </div>
  );
};

export default Section7;
