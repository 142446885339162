import Button from "../Button";
import "./style.scss";

const Section3 = () => {
  return (
    <div className="containerSection3">
      <div className="content">


        <h1 className="bg-orange">
          Ce challenge de 28 jours est fait pour toi si :
        </h1>
        <div className="card">

          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Tu as connu cet effet de yoyo toute ta vie : perdre du poids, en reprendre, la sensation d’avoir faim constamment… Qui au fil du temps te fait plus craquer qu’autre chose.
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Malgré tes séances de sport, tu n’arrives pas à prendre du muscle comme tu le voudrais.
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Tu prends plus de masse graisseuse que musculaire.
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Tu stagnes et tu n'arrives pas à avoir un corps dessiné.
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Tu as pris du poids au fil des années et les kilos se sont accumulés.
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Tu es fatigué(e) d’avoir essayé plein de solutions qui n’ont pas vraiment fonctionné.
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Tu as peut-être peur d’échouer encore une fois et de te sentir encore plus mal que maintenant
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Tu ne veux surtout plus connaître de culpabilité ni de frustration à ne pas partager tes soirées avec tes amis (à boire et à manger ce que tu veux, même un burger et des frites)
            </p>
          </div>
          <div className="line border-bottom">
            <span className="check">✔</span>
            <p>
              Tu as peu de temps pour te consacrer à une pratique sportive
            </p>
          </div>
          <div className="line ">
            <span className="check">✔</span>
            <p>
              Ton quotidien a changé (enfants, travail, rythme de vie plus sédentaire…)
            </p>
          </div>
        </div>

        <Button section="section-3" />
      </div>
    </div>
  );
};

export default Section3;
