import React from "react";
import Logo from "../../../images/TwentyEightDays/Logo-Alphabody-White.png";
import "./style.scss";
import Button from '../Button'

const Section1 = () => {

  return (
    <div className="containerSection1">

      <div className="content">

        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>

        <h1 className="white">
          TRANSFORME TON CORPS EN UN PHYSIQUE<br />
          <strong>
            <span className="orange">PLUS MUSCLÉ, PLUS TONIQUE ET MOINS GRAS</span><br />
          </strong>
          EN SEULEMENT 28 JOURS
        </h1>

        <h3 className="white">
          Deviens la meilleure version de toi-même grâce à la méthode ultime développée par des coachs professionnels et obtient un corps moins gras et plus musclé sans privation, sans cardio ni salle de sport, en 28 jours.
        </h3>
        <h1
          className="orange">
          <strong>Prêt à relever ce challenge <br />et à être fier(e) de toi ?</strong>
        </h1>

        <div className="video-ctn">
          <iframe src="https://player.vimeo.com/video/757105117?h=56b4960ee8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            width="1920" height="1080"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen title="CHALLENGE 28D VSL">
          </iframe>
        </div>
        <p className="white">
          🔊  Pense à activer le son lors de la lecture de la vidéo pour ne
          manquer aucune information
        </p>

        <Button section="section-1" />
      </div>

    </div>
  );
};

export default Section1;
