import Button from "../Button";
import "./style.scss";

const Section8 = () => {

  return (
    <div className="container7dcSection8">
      <div className="content">
        <h1 className="black">Maintenant tu as deux choix :</h1>

        <div className="list">
          <div className="line">
            <p>
              Soit tu penses que c’est pas pour toi, tu restes sceptique, tu
              préfères dépenser 7€ dans un mcdo ou une pizza, et tu ne fais
              rien.
            </p>
          </div>
          <div className="line">
            <p>
              Soit tu décides de croire en toi et de te reprendre en main, Et de
              commencer ta transformation physique MAINTENANT, en perdant
              jusqu’à 4 kilos d’ici une semaine.
            </p>
          </div>
          <div className="line">
            <h3>
              <strong>Es-tu prêt pour débuter ta transformation ?</strong>{" "}
            </h3>
          </div>
        </div>

        <Button section="section-8" bg="white" />

      </div>
    </div>
  );
};

export default Section8;
