

export const addToCart = (data) => {
  for (const item of data.items) {
    window.snaptr('track', 'ADD_CART', {
      number_items: item.quantity,
      item_ids: [item.item_id],
      currency: "EUR",
      price: item.price
    })
  }
};
