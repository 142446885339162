const FB_PIXEL_ID = 872321959914553;

export const addToCart = (data) => {
  let contents = []
  for (const item of data.items) {
    contents.push({
      'id': item.item_id,
      'quantity': item.quantity
    })
  }
  event('AddToCart', {
    content_type: 'product',
    contents: contents,
    value: data.value,
    currency: 'EUR'
  }
  )
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
const event = (name, options = {}) => {
  window.fbq('track', name, options);
};
