import React from "react";
import "./style.scss";

const BannerPromo = () => {

  return (
    <div className="bannerCtn">
      <div className="content">
        <h2 className="black">
          <strong>
            💣 OFFRE LIMITÉE : -50% UNIQUEMENT POUR LES LECTEURS DE CETTE PAGE
          </strong>
        </h2>
      </div>
    </div>
  );
};

export default BannerPromo;
