import ImmersionTestimony from "../../components/Immersion/Testimony";
import Footer from "../../components/Immersion/Footer";

const Testimony = () => {
  return (
    <>
      <ImmersionTestimony />
      <Footer />
    </>
  );
};

export default Testimony;
