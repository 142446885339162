import React from "react";
import Logo from "../../../images/TwentyEightDays/Logo-Alphabody-White.png";
import "./style.scss";
import Button from "../Button";

const Section1 = () => {
  return (
    <div className="container7dcVslSection1">
      <div className="content">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>

        <div className="video-ctn">
          <iframe src="https://player.vimeo.com/video/757105295?h=4ad35a1e56&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            width="1920" height="1080"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen title="VSL 7DC">
          </iframe>
        </div>

        <Button section="bouton-vsl" />
      </div>
    </div>
  );
};

export default Section1;
