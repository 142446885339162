import "./style.scss";

const Footer = () => {
  let year = new Date();

  return (
    <div className="footerCtn">
      <p className="copyright">&copy; AlphaBody {year.getFullYear()}</p>
      <div className="links">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://mentions.alphabody.fr/donnees-privees"
        >
          Politique de confidentialité
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://mentions.alphabody.fr/conditions-generales-de-ventes"
        >
          Termes & Conditions
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://mentions.alphabody.fr/mentions-legales"
        >
          Mentions légales
        </a>
      </div>
    </div>
  );
};

export default Footer;
