/* eslint-disable jsx-a11y/anchor-is-valid */
import image from "../../../images/TwentyEightDays/motiv-3.svg";
import image2 from "../../../images/TwentyEightDays/liberte.svg";
import "./style.scss";

const Section4 = () => {
  return (
    <div className="containerSection4">
      <div className="content">
        <div className="container-text">
          <h2 className="grey">
            <strong>Alors avant que tu le penses, je te rassure tout de suite, non, tu n’es pas un cas désespéré !</strong>
          </h2>
          <p className="grey">
            Tu penses certainement que tu n’as pas ou plus assez de volonté pour suivre encore un régime qui te donnera plus de frustration que de résultats sur le long terme.
          </p>
          <p className="grey">
            J’ai connu ça moi aussi, il y a des années. Et je sais parfaitement ce que tu ressens.
          </p>

          <h2 className="grey">
            <strong>Est-ce que ça t’arrive toi aussi de culpabiliser si tu n’arrives pas à perdre du gras, à te tenir à un régime et de ne pas avoir les résultats que tu espères tellement ?</strong>
          </h2>
          <p className="grey">
            Tu as peut-être honte de ton corps actuel, tu te sens mal dans ta peau et même jugé par les autres…
          </p>
          <p className="grey">
            <strong> Moi, ça m’arrivait constamment.</strong>
          </p>
          <p className="grey">
            Je me mettais une telle pression que même mon entourage (ma famille, mes collègues de boulot, ma copine de l’époque) subissait ce que je traversais…
          </p>
          <p className="grey">
            Je sais que c’est terrible de ne pas se sentir fier de soi, de perdre confiance en soi-même…
          </p>
          <p className="grey">
            Le pire dans tout ça, c’est que plus on se sent mal, plus on se compare aux autres. Et avec les réseaux sociaux (type Instagram) cela n’arrange pas les choses.
          </p>
          <p className="grey">
            <strong>On se sent nul(le), dépassé(e)….</strong>
          </p>
          <p className="grey">
            Tu en viens peut-être à te dire que ce n’est pas fait pour toi, que ça ne fonctionne pas avec ton métabolisme, que c’est génétique, que tu es trop maigre pour devenir musclé, ou bien que ton corps a été trop abîmé par les grossesses, une forte prise de poids, etc.
          </p>

          <h2 className="grey">
            <strong>En vérité, le problème ne vient pas de toi.<br />
              Ni des autres.</strong>
          </h2>
          <p className="grey">
            Mais de toutes les fausses croyances que tu as sur toi et sur le sujet en question.
          </p>
        </div>
        <div className="container-img">
          <img src={image} alt="super-hero" />
        </div>
      </div>
      <div className="content">
        <div className="container-img">
          <img src={image2} alt="liberty" />
        </div>
      </div>

    </div>
  );
};

export default Section4;
