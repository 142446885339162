import Optin from "../../components/Immersion/Optin";
import Footer from "../../components/Immersion/Footer";

import "../../styles/index.scss";

const Immersion = () => {
  return (
    <>
      <Optin />
      <Footer />
    </>
  );
};

export default Immersion;
