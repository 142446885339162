import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import TwentyEightDays from "./pages/TwentyEightDays";
import SevenDays from "./pages/SevenDays";
import SevenDaysVsl from "./pages/SevenDaysVsl";

import Immersion from "./pages/Immersion";
import Form from "./pages/Immersion/form";
import Merci from "./pages/Immersion/merci";
import ImmersionTestimony from "./pages/Immersion/testimony"

import "./App.scss";

function App() {
  let location = useLocation();

  return (
    <div className="App">
      <Routes>

        {/* ROUTES 28dc */}
        <Route path="/28-jours" element={<TwentyEightDays />} />

        {/* ROUTES 7dc */}
        <Route path="/7-jours" element={<SevenDays />} />
        <Route path="/7-jours-vsl" element={<SevenDaysVsl />} />

        {/* TOUTES LES AUTRES ROUTES ENVOIES VERS LE 28dc */}
        <Route
          path="*"
          element={
            <Navigate to={`/28-jours${location.search}`} replace={true} />
          }
        />

        {/* ROUTES IMMERSION */}
        <Route path="/immersion" element={<Immersion />} />
        <Route path="/immersion/formulaire" element={<Form />} />
        <Route path="/immersion/merci" element={<Merci />} />
        <Route path="/immersion/temoignages" element={<ImmersionTestimony />} />


      </Routes>
    </div>
  );
}

export default App;
