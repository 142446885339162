import "./style.scss";
import { useLocation } from "react-router-dom";

import * as tracking from '../../../utils/tracking'

const Button = ({ section, bg }) => {
  let location = useLocation();

  const click = () => {

    tracking.addToCart({
      'event': 'add_to_cart',
      'event_place': `btn-${section}`,
      'product': '28dc',
      'value': 47,
      'funnel': '28dc',
      'page': location.pathname,
      'items': [{
        item_name: "Challenge 28 Jours",
        item_id: '28dc',
        price: 47.00,
        quantity: 1
      }]
    })
    window.location.href = `https://www.paiement.alphabody.fr/product/28dc${location.search}`
  }


  return (
    <button className={bg === "white" ? "btn-goCheckout-white" : "btn-goCheckout"} onClick={() => click()}>
      <div className="arrow" >➡</div>
      <h3>
        JE PASSE À L'ACTION ET JE REJOINS LE CHALLENGE DES 28 JOURS
      </h3>
    </button>
  );
};

export default Button;
