import React from "react";
import Logo from "../../../images/TwentyEightDays/Logo-Alphabody-White.png";
import "./style.scss";
import Button from "../Button";

const Section1 = () => {
  return (
    <div className="container7dcSection1">
      <div className="content">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>

        <h1 className="white">
          PERDS JUSQU'À&nbsp;
          <strong>
            <span className="orange">4 KILOS</span>&nbsp;
          </strong>
          EN SEULEMENT&nbsp;
          <strong>
            <span className="orange">7 JOURS</span>
          </strong>
        </h1>

        <h3 className="white">
          <ul>
            <li>Sans faire une heure de cardio par jour</li>
            <li>Sans passer ta vie à la salle</li>
            <li>Sans devoir te priver avec des régimes stricts</li>
          </ul>
        </h3>

        <Button section="section-1" />
      </div>
    </div>
  );
};

export default Section1;
