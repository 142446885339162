
export const addToCart = (data) => {
  if (window.gtag) {
    for (const item of data.items) {
      window.gtag('event', 'conversion', {
        //regarder si il y a d'autres paramètres qui pourraient servir dans la doc google ads
        'send_to': 'AW-844515507/blf-CKGUpdEDELOR2ZID',
        'value': item.price,
        'transaction_id': item.item_id,
        'currency': 'EUR',
        'event_callback': window.location
      });
    }
  }
}