/* eslint-disable jsx-a11y/anchor-is-valid */
import "./style.scss";
import { useLocation } from "react-router-dom";

import * as tracking from "../../../utils/tracking";

const Button = ({ section, bg }) => {
  let location = useLocation();

  const click = () => {
    tracking.addToCart({
      event: "add_to_cart",
      event_place: `btn-${section}`,
      product: "7dc",
      value: 7,
      funnel: "7dc",
      page: location.pathname,
      items: [
        {
          item_name: "Challenge 7 Jours",
          item_id: "7dc",
          price: 7.0,
          quantity: 1,
        },
      ],
    });
    window.location.href = `https://www.paiement.alphabody.fr/product/7dc${location.search}`;
  };

  return (
    <button
      className={bg === "white" ? "btn-goCheckout-white" : "btn-goCheckout"}
      onClick={() => click()}
    >
      <div className="arrow">➡</div>
      <h3>OUI, JE REJOINS LE CHALLENGE 7 JOURS</h3>
    </button>
  );
};

export default Button;
