import Shopping from "../../../images/SevenDays/shopping.png";
import Strategy from "../../../images/SevenDays/strategy.png";
import Sport from "../../../images/SevenDays/sport.png";
import "./style.scss";

const Section5 = () => {
  return (
    <div className="container7dcSection5">
      <div className="content">
        <h1 className="black">
          Les 100 premières personnes qui rejoindront le challenge recevront en
          bonus:
        </h1>

        <div className="list">
          <div className="line">
            <img className="iconSection5" src={Shopping} alt="" />
            <p>
              La liste de courses pour la semaine, pour savoir exactement quoi
              cuisiner et ne pas se retrouver à court d’imagination
            </p>
          </div>
          <div className="line">
            <img className="iconSection5" src={Strategy} alt="" />
            <p>
              Un plan de nutrition adapté à ta morphologie, pour être certain de
              manger en bonne quantité
            </p>
          </div>
          <div className="line">
            <img className="iconSection5" src={Sport} alt="" />
            <p>
              3 séances de sport, à faire directement depuis chez toi et sans
              aucun équipement, faisables même si tu as très peu de temps pour
              toi (famille, travail…)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
