import React from "react";
import { useLocation } from "react-router-dom";

import BannerPromo from "../../components/TwentyEightDays/BannerPromo";
import CookiesBar from "../../components/General/CookiesBar";
import Footer from "../../components/General/Footer";
import Section1 from "../../components/TwentyEightDays/Section1";
import Section2 from "../../components/TwentyEightDays/Section2";
import Section3 from "../../components/TwentyEightDays/Section3";
import Section4 from "../../components/TwentyEightDays/Section4";
import Section5 from "../../components/TwentyEightDays/Section5";
import Section6 from "../../components/TwentyEightDays/Section6";
import Section7 from "../../components/TwentyEightDays/Section7";
import Section8 from "../../components/TwentyEightDays/Section8";
import Section9 from "../../components/TwentyEightDays/Section9";

import * as tracking from '../../utils/tracking'

import "../../styles/index.scss";

const TwentyEightDays = () => {
  let location = useLocation();

  React.useEffect(() => {

    document.title = "Challenge 28 Jours"

    tracking.viewItems({
      'event': 'view_item',
      'value': 47,
      'funnel': '28dc',
      'page': location.pathname,
      'items': [{
        item_name: "Challenge 28 Jours",
        item_id: '28dc',
        price: 47.00,
        quantity: 1
      }]
    })
  }, [])

  return (
    <>
      <CookiesBar />
      <BannerPromo />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Footer />
    </>
  );
};

export default TwentyEightDays;
