
export const viewItems = (data) => {

  window.dataLayer.push({
    'event': data.event,
    'value': data.value,
    'funnel': data.funnel,
    'page': data.page,
    'items': data.items
  })

}


export const addToCart = (data) => {

  window.dataLayer.push({
    'event': data.event,
    'event_place': data.event_place,
    'product': data.product,
    'value': data.value,
    'funnel': data.funnel,
    'page': data.page,
    'items': data.items
  })

}
