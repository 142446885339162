import "./style.scss";
import Button from "../Button";

import testi1 from '../../../images/TwentyEightDays/testi/testi-1.jpg'
import testi2 from '../../../images/TwentyEightDays/testi/testi-2.png'
import testi3 from '../../../images/TwentyEightDays/testi/testi-3.png'
import testi4 from '../../../images/TwentyEightDays/testi/testi-4.jpg'
import testi5 from '../../../images/TwentyEightDays/testi/testi-5.png'
import testi6 from '../../../images/TwentyEightDays/testi/testi-6.png'

const Section8 = () => {
  return (
    <div className="containerSection9">
      <div className="content">

        <h2
          className="bg-white"
        >
          Avant toi, beaucoup d’autres personnes ont fait confiance au concept Alphabody et au challenge de 28 jours :
        </h2>

        <div className="img-container">
          <div className="image">
            <img src={testi1} alt="avant-apres-1" />
          </div>
          <div className="image">
            <img src={testi2} alt="avant-apres-2" />
          </div>
          <div className="image">
            <img src={testi3} alt="avant-apres-3" />
          </div>
          <div className="image">
            <img src={testi4} alt="avant-apres-4" />
          </div>
          <div className="image">
            <img src={testi5} alt="avant-apres-5" />
          </div>
          <div className="image">
            <img src={testi6} alt="avant-apres-6" />
          </div>
        </div>

        <div
          className="text">
          <h3 className="white">
            Ce programme t’aidera non seulement à obtenir un meilleur corps en 28 jours mais aussi il te donnera un équilibre et une hygiène de vie que tu pourras conserver sur l’année entière voire même durant toute ta vie.
          </h3>
        </div>
        <Button section="section-9" />
      </div>
    </div>
  );
};

export default Section8;
